
#editdiv {
    border: 2px solid grey;
    padding: 10px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    color: darkgrey

}


#linkiddiv {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 700; 
    border: 2px solid grey;
}

#buttondiv {
    border: 2px solid grey;
    padding: 5px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
}



#datepicker {
    width: 100px;
    font-size: 14px;
    font-weight: 700;
    color: darkgrey;
    line-height: 50px;
    align-items: center;
    display: inline-block;
}



#linkid {
    width: 70px;
    font-size: 14px;
    font-weight: 700; 
    white-space: nowrap;
    line-height: 20px;;
    color: darkgrey;
    align-items: center;
    display: inline-block;

    
}

#portion {
    width: 50px;
    font-size: 14px;
    font-weight: 900; 
}



#session {
    
    font-size: 14px;
    font-weight: 900;    
    margin-left: 10px;
    white-space: nowrap
}