.ag-theme .ag-numeric-cell .ag-cell-wrapper {
    flex-direction: row-reverse;
    color: 'pink';
}

body::-webkit-scrollbar {
    width: 10px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track #tabdiv {
    background: orange;        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: blue;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid orange;  /* creates padding around scroll thumb */
  }

  #tabdiv {
    height: 500px;
    overflow: auto;
    
  }

  #tabdiv::-webkit-scrollbar {
      width: 15px;
      
  }

  #tabdiv::-webkit-scrollbar-track {
    background: black; 
  }
  
  #tabdiv::-webkit-scrollbar-thumb {
    background-color: lightgrey; 
    border-radius: 20px;    
    border: 3px solid darkgrey;
    
  }

.ag-root ::-webkit-scrollbar {
    height: 10px;
}

.ag-root ::-webkit-scrollbar-track  {
  background: black; 
}

.ag-root ::-webkit-scrollbar-thumb {
  background-color: lightgrey; 
  border-radius: 15px;    
  border: 3px solid darkgrey;
}  

#dateid {
    background-color: orange;
    color: blue;
    font-weight: bold;

}

#unlocked {
  color: lightgray;
  font-style: italic;
  font-weight: bold;
  text-align: right;
  cursor: pointer;
}

#locked {
  text-align: right;
  font-size: 11px;
}

#unlocked2 {
  color: lightgray;
  font-style: italic;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
}

#locked2 {
  text-align: left;
  font-size: 11px;
}

.datediv {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

#datepicker {
  width: 90px;
  font-size: 1em;
  font-weight: 900;
}

#showtotals {
  font-size: 0.25em;
  font-weight: 900; 
}

#msgspan {
  
  font-size: 0.75em;
  color: darkgreen;
  padding: 10px;
  margin-top: 10px
}
  
