.custom-tooltip {
  position: absolute;
  border: 1px solid cornflowerblue;
  overflow: visible;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 1;
}

.custom-tooltip p {
  margin: 1px;
  white-space: nowrap;
  background-color: orange;
  color: cornflowerblue;
  font-size: 10px

}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}